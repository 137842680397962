export const DATE_REGEX = /^\s*(?:(?:(?<month>\d\d?)\s*[-\\/]?\s*(?<day>\d\d?)(?:\s*[-\\/]?\s*(?<year>\d{2}(?:\d{2})?)))|(?:(?<year2>\d{4})\s*[-\\/]?\s*(?<month2>\d\d?)\s*[-\\/]?\s*(?<day2>\d\d?)))(?:(?:T|\s*)(?<hour>\d\d?)[: ]?(?<minute>\d\d)(?:[: ](?:(?<second>\d\d)(?:[.](?<millisecond>\d{1,3})(?<nanosecond>\d{0,6}))?))?(?:\s*(?<divider>(?:A[.]?M[.]?|P[.]?M[.]?)))?(?<timezone>Z|\s*?[+-]\d\d?(?::\d\d?)?)?)?\s*$/i;

export function parseDate(value: string | number | boolean | null | undefined, schemaVersion: number) {
    const result = tryParseDate(value);
    if (result != null) {
        return result;
    } else if (schemaVersion < 8 && value != null && !/^\s*$/.test(value.toString())) {
        // See FormDefinitionSchemaVersion
        return new Date(value.toString());
    } else {
        return null;
    }
}

export function tryParseDate(value: string | number | boolean | null | undefined) {
    if (value == null || /^\s*$/.test(value.toString())) {
        return null;
    }
    const match = DATE_REGEX.exec(value.toString());
    if (match != null && match.groups != null) {
        //eslint-disable-next-line prefer-const
        let [year, month, day, hour, minute] = ["year", "month", "day", "hour", "minute"].map(i => match.groups![i] || match.groups![i + "2"]).map(i => (i == null ? null : parseInt(i))) as [number, number, number, number | null, number | null];
        hour = hour || 0;
        minute = minute || 0;
        if (year < 50) year += 2000;
        if (year < 100) year += 1900;
        let second;
        if (match.groups.millisecond) {
            second = parseFloat(match.groups.second + "." + match.groups.millisecond);
        } else if (match.groups.second) {
            second = parseFloat(match.groups.second);
        } else {
            second = 0;
        }
        const divider = match.groups.divider ? match.groups.divider.toLowerCase() : null;
        if (divider != null) {
            const pm =
                divider == "pm" || divider == "p.m." || divider == "p.m" || divider == "pm."
                    ? true
                    : divider == "am" || divider == "a.m." || divider == "a.m" || divider == "am."
                      ? false
                      : (() => {
                            throw new Error("Regex shouldn't return this divider: " + divider);
                        })();
            if (hour == 12) {
                if (!pm) {
                    // 12:30am = 0:30
                    hour = 0;
                } else {
                    // 12:30pm = 12:30
                }
            } else if (pm) {
                hour += 12;
            }
        }
        const timezone = match.groups.timezone;
        if (timezone != null) {
            // TODO?
        }
        if (month <= 0 || month > 12 || day < 1 || day > 31 || hour < 0 || hour > 23 || minute < 0 || minute > 59 || second < 0 || second > 60) {
            //console.log({year, month, day, hour, minute, second});
            return null;
        }
        const result = new Date(year, month - 1, day, hour, minute, second);
        if (result.getDate() != day) {
            //console.log({year, month, day, hour, minute, second});
            // For example, Fed 30
            return null;
        }
        return result;
    } else {
        //console.log({value});
        return null;
    }
}
export function DfStrictParseFloat(value: string | null | undefined | number | boolean) {
    value = value == null || !/^\s*([+-]?[$]?|[+-]?[$]?)?([0-9,]+([.][0-9]+)?|[.][0-9]*)\s*$/.test(value.toString()) ? null : value.toString().replace(/([$,]|\s)+/g, "");
    return parseFloat(value!);
}
