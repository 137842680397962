import { UserData } from "@/cs-ts-bridge/cs/UserData";
import type { mainStore } from "@/mainStore";
export async function getXsrfToken(context: mainStore) {
    let xsrfToken = context.xsrfToken;
    if (!xsrfToken) {
        xsrfToken = await refreshXsrfToken(context);
    }
    return xsrfToken;
}

export function refreshXsrfToken(context: mainStore): Promise<string> {
    if (context.xsrfTokenPromise == null) {
        context.xsrfTokenPromise = (async () => {
            const url = await context.urlFetcher("getUserData");
            const response = await fetch(url, { cache: "reload" });
            const json = (await response.json()) as UserData;
            const xsrfToken = json.XsrfToken ?? json.XrsfToken; // TODO backward compatibility - remove after release // cspell:ignore xrsf
            context.setXsrfToken(xsrfToken);
            return xsrfToken;
        })();
    }
    return context.xsrfTokenPromise;
}

export async function getXsftHeader(context: mainStore) {
    return {
        "X-XSRF-TOKEN": await getXsrfToken(context),
    };
}
