import type { mainStore } from "@/mainStore";
import { refreshXsrfToken, getXsrfToken } from "./getXsrfToken.3";
interface RequestOptions extends Omit<RequestInit, "headers"> {
    headers?: HeadersInit & {
        "X-Return401"?: boolean;
        "X-AccessGov-VersionHash"?: string;
        "X-XSRF-TOKEN"?: string | null;
    };
}
export function throwIfNonSuccessStatus(response: Response) {
    if (response.status != 200) {
        throw new Error("error: " + response.status);
    }
}
export async function fetchAuth(context: mainStore, url: string, options?: RequestOptions, fetchM?: (input: RequestInfo | URL, init: RequestInit) => Promise<Response>) {
    if (options == null) {
        options = {};
    }
    if (options.headers == null) {
        options.headers = {};
    }
    options.headers["X-Return401"] = true;
    const versionHash = context.globalConfigData?.VersionHash?.toString();
    url = addQueryParam(url, "4dfa77b6", versionHash);
    options.headers["X-AccessGov-VersionHash"] = versionHash;
    return await fetch(url, options).then(async response => {
        const latestVersionHash = response.headers.get("X-AccessGov-VersionHash");
        if (latestVersionHash != null && latestVersionHash != versionHash) {
            context.setVersionMismatch(latestVersionHash);
        }
        if (response.status == 401) {
            const mode = await response.json();
            context.setNeedsLogin(mode);
            await context.needsLoginPromise;
            return await (fetchM ?? fetch)(url, options!);
        } else {
            return response;
        }
    });
}

function addQueryParam(url: string, name: string, value: string) {
    if (value != null) {
        const urlUrl = new URL(url);
        const searchParams = new URLSearchParams(urlUrl.search);
        searchParams.set(name, value);
        const newUrl = new URL(`${urlUrl.origin}${urlUrl.pathname}?${searchParams}`);
        return newUrl.href;
    } else {
        return url;
    }
}

export async function fetchXsrf(context: mainStore, url: string, options?: RequestOptions): Promise<Response> {
    if (options == null) {
        options = {};
    }
    if (options.headers == null) {
        options.headers = {};
    }
    options.headers["X-XSRF-TOKEN"] = await getXsrfToken(context);
    return await fetchAuth(context, url, options).then(async response => {
        if (response.status == 400) {
            options!.headers!["X-XSRF-TOKEN"] = await refreshXsrfToken(context);
            return await fetchAuth(context, url, options, (a, b) => fetchXsrf(context, a as string, b));
        } else {
            return response;
        }
    });
}
